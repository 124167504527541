<aside #aside>
  <div>
    <div
      class="close-menu"
      (click)="closeResponsiveMenu()"
    >
      <i class="fa fa-times"></i>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <img id="logo" src="assets/images/logo_plus.svg" alt="Logo Nabook" />
    </div>
    <app-user-box></app-user-box>
    <ul id="mainMenu">
      <li *ngIf="!authService.permissions.USER_MANAGER">
        <a
          [routerLink]="['/', 'dashboard']"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
          ><i class="fa fa-fw fa-server"></i>
          <span>{{ 'NAVIGATION.DASHBOARD' | translate }}</span></a
        >
      </li>
      <li *ngIf="!authService.permissions.USER_MANAGER">
        <a
          [routerLink]="['/', 'my-naboo']"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
          ><i class="fa fa-fw fa-layer-group"></i>
          <span>{{ 'NAVIGATION.MY_NABOO' | translate }}</span></a
        >
      </li>
      <li *ngIf="!authService.permissions.USER_MANAGER">
        <a
          [routerLink]="['/', 'my-display']"
          [routerLink]="['/', 'my-display']"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
          ><i class="fa fa-fw fa-chalkboard-teacher"></i>
          <span>{{ 'NAVIGATION.MY_DISPLAY' | translate }}</span></a
        >
      </li>
      <li *ngIf="!authService.permissions.USER_MANAGER">
        <a
          [routerLink]="['/', 'recipes']"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
          ><i class="fa fa-fw fa-utensils"></i>
          <span>{{ 'NAVIGATION.LAINOX_RECIPES' | translate }}</span></a
        >
      </li>
      <li *ngIf="!authService.permissions.USER_MANAGER">
        <a
          [routerLink]="['/', 'devices']"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
          ><i class="fa fa-cogs"></i>
          <span>{{ 'NAVIGATION.DEVICES' | translate }}</span></a
        >
      </li>
      <li *ngIf="!authService.permissions.USER_MANAGER && false">
        <a
          [routerLink]="['/', 'menu']"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
          ><i class="fa fa-fw fa-book-open"></i>
          <span>{{ 'NAVIGATION.MENU' | translate }}</span></a
        >
      </li>
      <li *ngIf="false">
        <a
          [ngClass]="{ disabled: true }"
          [routerLink]="['/', 'food-cost']"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
          ><i class="fa fa-fw fa-euro-sign"></i>
          <span>{{ 'NAVIGATION.FOOD_COST' | translate }}</span></a
        >
      </li>
      <li *ngIf="false">
        <a
          [ngClass]="{ disabled: true }"
          [routerLink]="['/', 'grocery-list']"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
          ><i class="fa fa-fw fa-shopping-cart"></i>
          <span>{{ 'NAVIGATION.GROCERY_LIST' | translate }}</span></a
        >
      </li>
      <li>
        <a (click)="logout()" href="javascript:void(0)"
          ><i class="fa fa-fw fa-sign-out-alt"></i>
          <span>{{ 'NAVIGATION.LOGOUT' | translate }}</span></a
        >
      </li>
    </ul>
  </div>
  <div class="pt-3">
    <app-language-box></app-language-box>
    <p id="app-version">{{ APP_VERSION }}</p>
  </div>
</aside>
