import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { countryUserDetailList, languagesList } from 'src/app/core/utils';


@Component({
  selector: 'app-service-user-info',
  templateUrl: './service-user-info.component.html',
  styleUrls: ['./service-user-info.component.scss']
})
export class ServiceUserInfo implements OnInit {
  @Input() controlValue: UntypedFormGroup;
  @Input() isNew: boolean = false;

  countryItems = countryUserDetailList;
  localeItems = languagesList;
  showPsw: boolean = false;
  showConfirmPsw: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  togglePassword( input: number ) {
    if ( input === 0 ) {
      this.showPsw = !this.showPsw;
    } else if ( input === 1 ) {
      this.showConfirmPsw = !this.showConfirmPsw;
    }
  }

}
