import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  KeycloakBaseUserCreationDtoNBK,
  UserKeycloakDtoNBK,
  UserService
} from 'src/app/api/nbk';
import { markFormGroupTouched } from 'src/app/core/utils';

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss']
})
export class CreateUserModalComponent implements OnInit {
  pswVPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/;

  @Input() roles: KeycloakBaseUserCreationDtoNBK.RolesEnum [];
  @Output() userCreated: EventEmitter<UserKeycloakDtoNBK> = new EventEmitter();

  userFormGroup: UntypedFormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private ts: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {

    this.userFormGroup = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      phoneNumber: new UntypedFormControl('',[Validators.required, ]),
      country: new UntypedFormControl(null, [Validators.required]),
      locale: new UntypedFormControl(null),
      companyName: new UntypedFormControl(null),
      password: new UntypedFormControl('', [ Validators.required, this.patternValidator(this.pswVPattern, 'pswPattern') ]),
      confirmPassword: new UntypedFormControl('', [ Validators.required, this.patternValidator(this.pswVPattern, 'pswPattern')])
    }, { validators: this.matchPassword });

  }

  patternValidator(pattern: string | RegExp, errorKey: string ): ValidatorFn {
    const patternValidator = Validators.pattern(pattern);
  
    return (control: AbstractControl): ValidationErrors | null => {
      const validationResult = patternValidator(control);
      return validationResult ? { [errorKey]: true } : null;
    };
  }

  matchPassword (formGroup: AbstractControl) {
    const psw = formGroup.get('password')?.value;
    const confirmPsw = formGroup.get('confirmPassword')?.value;

    if (!psw || !confirmPsw) {
      return null;
    }

    return psw !== confirmPsw ? { pswMismatch: true } : null;
  }

  createNewUser() {
    if (this.userFormGroup.valid) {
      const user = { ...this.userFormGroup.value, roles: this.roles };
      this.userService
        .createUser(false,user)
        .subscribe((res: UserKeycloakDtoNBK) => {
          this.userCreated.emit(res);
          this.activeModal.close();
        });
    }  else {
      markFormGroupTouched(this.userFormGroup);
    }
  }

}
