import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { DeviceService, DeviceTechnicianAssociationDtoNBK, PairTechniacianDevicesRequestNBK, UserKeycloakDtoNBK, UserService } from '../../api/nbk';
import { RowDetail } from 'src/app/shared/datatable/datatable-paginated/datatable-paginated.component';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';
import { Subject, take, takeUntil, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateUserModalComponent } from '../create-user-modal/create-user-modal.component';
import { DeviceTechnicianAssociationModal } from 'src/app/shared/modals/device-technician-association-modal/device-technician-association-modal.component';

@Component({
  selector: 'app-service-user',
  templateUrl: './service-user.component.html',
  styleUrls: ['./service-user.component.scss']
})
export class ServiceUserComponent implements OnInit, OnDestroy {
  dataSource = new ODataFiltrableDataSource<UserKeycloakDtoNBK>();

  unsubscribe$ = new Subject<void>()

  rowDetailFilter: string | undefined = undefined;
  rowDetailDataSource = new ODataFiltrableDataSource<DeviceTechnicianAssociationDtoNBK>();
  
  columns: TableColumn[] = [];

  filterControl: UntypedFormControl = new UntypedFormControl('', []);

  @ViewChild('actionTpl', { static: true }) actionTpl: ElementRef<HTMLElement>;
  @ViewChild('rowDetailTemplate', { static: true }) rowDetailTemplate: TemplateRef<HTMLElement>;

  rowDetailHandler: RowDetail;
  selectionHandler: SelectedItemsHandler<UserKeycloakDtoNBK>;

  constructor(
    private ts: TranslateService,
    private toastrService: ToastrService,
    private userService: UserService,
    private deviceService: DeviceService,
    private modalService: NgbModal
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'email',
        name: this.ts.instant('ROLES.EMAIL')
      },
      {
        prop: 'firstName',
        name: this.ts.instant('ROLES.NAME')
      },
      {
        prop: 'lastName',
        name: this.ts.instant('ROLES.LASTNAME')
      },
      {
        prop: '',
        cellTemplate: this.actionTpl,
        maxWidth: 100
      }
    ];

    this.dataSource.fetchFunction = (
      top,
      skip,
      count,
      _orderBy,
      filter,
      search
    ) => {
      return this.userService.getServiceUsers(
        top,
        skip,
        count,
        _orderBy,
        filter,
        search
      );
    };

    this.rowDetailHandler = {
      template: this.rowDetailTemplate,
      loadDetail: (row: UserKeycloakDtoNBK) => {
        this.rowDetailFilter = `userId eq '${row.id}'`;
        this.rowDetailDataSource.loadData();
      }
    }

    this.selectionHandler = new SelectedItemsHandler<UserKeycloakDtoNBK>( 
      (item: UserKeycloakDtoNBK)  => false, 'id' );
    
    this.dataSource.limit = 10;
    this.dataSource.loadData();

    this.rowDetailDataSource.fetchFunction = (top, skip, count, _orderBy, filter) => {
      return this.deviceService
        .getDeviceTechnicians(
          localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
          top,
          skip,
          count,
          _orderBy,
          this.rowDetailFilter
        )
        .pipe(take(1));
    };
    this.rowDetailDataSource.loadData();
  }

  createUser() {
    const modalRef = this.modalService.open(CreateUserModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'modal-lg'
    });
    modalRef.componentInstance.roles = [ 'SERVICE' ];
    modalRef.componentInstance.userCreated.subscribe((v: UserKeycloakDtoNBK) => {
      this.dataSource.applyFilters();

      this.toastrService.success(
        this.ts.instant('USER.USER_CREATED'),
        this.ts.instant('GLOBAL.SUCCESS')
      );
    });
  }

  assignDevicesToUser() {
    const modalRef = this.modalService.open(DeviceTechnicianAssociationModal, {
      backdrop: 'static',
      modalDialogClass: 'modal-xl'
    });
    modalRef.componentInstance.modalTitle = "MY_DISPLAY.ASSIGN_DEVICE";
    modalRef.componentInstance.saveAssociation.subscribe((request: PairTechniacianDevicesRequestNBK) => {
      request.userIds = this.selectionHandler.items.map( u => u.id!);
      this.deviceService.pairTechniciansDevices(request)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.toastrService.success(
            this.ts.instant('MY_DISPLAY.DEVICE_ASSIGNED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        })
      ).subscribe();
    });
  }

  applyFilter() {
    this.dataSource.setSearch(this.filterControl.value);
    this.dataSource.loadData();
  }

  cleanFilter() {
    this.filterControl.reset();
    this.dataSource.removeSeach();
    this.dataSource.loadData();
  }


  
}
