<div id="user-box" [ngClass]="{ open: userInfoIsOpen }">
  <div class="user-name-logged" (click)="toggleInfo()">
    {{ mkc.user.firstName }} {{ mkc.user.lastName }}

    <i
      class="fa"
      [ngClass]="userInfoIsOpen ? 'fa-angle-up' : 'fa-angle-down'"
    ></i>
  </div>
  <ul id="user-menu" *ngIf="userInfoIsOpen">
    <li class="user-menu-item" id="user-profile">
      <a
        [routerLink]="['/', 'user-profile']"
        [routerLinkActive]="'active'"
        href="javascript:void(0)"
      >
        <i class="fa fa-user"></i>
        <span>{{ 'NAVIGATION.PROFILE' | translate }}</span>
      </a>
    </li>
    <li class="user-menu-item" id="user-profile">
      <a
        [routerLink]="['/', 'user-settings']"
        [routerLinkActive]="'active'"
        href="javascript:void(0)"
      >
        <i class="fa fa-sliders-h"></i>
        <span>{{ 'NAVIGATION.SETTINGS' | translate }}</span>
      </a>
    </li>
    <li
      class="user-menu-item"
      id="user-profile"
      *ngIf="
        authService.permissions.USER_MANAGER ||
        authService.permissions.SUPERUSER
      "
    >
      <a
        [routerLink]="['/', 'user-roles']"
        [routerLinkActive]="'active'"
        href="javascript:void(0)"
      >
        <i class="fa fa-user-tag"></i>
        <span>{{ 'NAVIGATION.ROLES' | translate }}</span>
      </a>
    </li>
  </ul>
</div>
