import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { MykeycloakService } from 'src/app/core/mykeycloak.service';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit {
  userInfoIsOpen = false;

  constructor(public mkc: MykeycloakService, public authService: AuthService) {}

  ngOnInit(): void {}

  toggleInfo(): void {
    this.userInfoIsOpen = !this.userInfoIsOpen;
  }
}
