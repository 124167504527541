import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { finalize, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { DeviceDtoNBK, DeviceService, UserKeycloakDtoNBK } from 'src/app/api/nbk';
import { IPairDevice } from 'src/app/core/device.utils';
import {
  getUrlRedirectPopupDevice,
  KEY_LANGUAGE_STORAGE
} from 'src/app/core/utils';
import { PairDeviceComponent } from 'src/app/shared/modals/pair-device/pair-device.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-devices',
  templateUrl: './dashboard-devices.component.html',
  styleUrls: ['./dashboard-devices.component.scss']
})
export class DashboardDevicesComponent implements OnInit {
  @Input() kcUser: UserKeycloakDtoNBK;
  userId: string;

  deviceList$: Observable<DeviceDtoNBK[]>;
  updateDeviceList$: Subject<void> = new Subject();
  

  constructor(
    private deviceService: DeviceService,
    private modalService: NgbModal,
    private ts: TranslateService,
    private toastrService: ToastrService
  ) {
    this.deviceList$ = this.updateDeviceList$.pipe(
      startWith(0),
      switchMap(() =>
        deviceService
          .getAllDevicesWIthDisplays(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, 5, 0)
          .pipe(map((res) => res.value || []))
      )
    );
  }

  ngOnInit(): void {
    this.userId = this.kcUser.id!;
  }

  refreshDeviceList(): void {
    this.updateDeviceList$.next();
  }

  pairDevice(): void {
    const modalRef = this.modalService.open(PairDeviceComponent, {
      backdrop: 'static'
    });

    modalRef.componentInstance.generateSecureCode
      .pipe(
        take(1),
        switchMap((serialNumber: string) =>
          this.deviceService.generateSecureCode(serialNumber)
        ),
        tap(() => (modalRef.componentInstance.modalWizard = 'SECURE_CODE'))
      )
      .subscribe();

    modalRef.componentInstance.pairDevice
      .pipe(
        take(1),
        switchMap((device: IPairDevice) =>
          this.deviceService.addCurrentUserToDevice(
            device.serialNumber,
            device.secureCode
          )
        ),
        tap(() => {
          this.toastrService.success(
            this.ts.instant('DEVICE.DEVICE_PAIRED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        }),
        finalize(() => {
          modalRef.close();
          this.refreshDeviceList();

          setTimeout(() => {
            const {
              keycloack: { baseUrl, realm },
              origin
            } = environment;
            const url = getUrlRedirectPopupDevice(baseUrl, realm, origin);
            window.open(url);
          }, 500);
        })
      )
      .subscribe();
  }
}
