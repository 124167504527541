import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  CompanyDtoNBK,
  CompanyService,
  UserKeycloakDtoNBK,
  UserService
} from '../api/nbk';
import { MykeycloakService } from '../core/mykeycloak.service';
import { changePasswordUrl } from '../core/utils';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  companyFormGroup: UntypedFormGroup;
  personalInformationFormGroup: UntypedFormGroup;
  sectorFormGroup: UntypedFormGroup;
  ovenFormGroup: UntypedFormGroup;
  agreePrivacy: UntypedFormControl;
  loggedUser: UserKeycloakDtoNBK;
  companyLoggedUser: CompanyDtoNBK;
  changePasswordUrl: string;

  unsubscribe$: Subject<void> = new Subject();
  company$: Subject<void> = new Subject();

  constructor(
    public mkc: MykeycloakService,
    private keycloakService: KeycloakService,
    private route: ActivatedRoute,
    private userService: UserService,
    private ts: TranslateService,
    private toastrService: ToastrService,
    private companyService: CompanyService
  ) {
    this.loggedUser = this.route.snapshot.data.userLogged;
    this.company$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() =>
          this.companyService.getCompanyById(+this.loggedUser.companyId!)
        )
      )
      .subscribe((company: CompanyDtoNBK) => {
        this.companyLoggedUser = { ...company };
        this.patchValues();
      });
    this.changePasswordUrl = changePasswordUrl;
  }

  ngOnInit(): void {
    this.companyFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl('', [Validators.required]),
      vatNumber: new UntypedFormControl('', []),
      phoneNumber: new UntypedFormControl('', []),
      email: new UntypedFormControl('', []),
      position: new UntypedFormControl('', []),
      notWorking: new UntypedFormControl(false, [])
    });

    this.personalInformationFormGroup = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      province: new UntypedFormControl('', []),
      city: new UntypedFormControl('', [Validators.required]),
      address: new UntypedFormControl('', [Validators.required]),
      addressNumber: new UntypedFormControl('', [Validators.required]),
      postalCode: new UntypedFormControl('', [Validators.required])
    });

    this.sectorFormGroup = new UntypedFormGroup({
      sector: new UntypedFormControl('', []),
      mealsPerDay: new UntypedFormControl(0, [])
    });

    this.ovenFormGroup = new UntypedFormGroup({
      ovenBrand: new UntypedFormControl('', [Validators.required]),
      ovenType: new UntypedFormControl('', [Validators.required]),
      ovenCapacity: new UntypedFormControl(0, [Validators.required , Validators.min(0)]),
      ovenYear: new UntypedFormControl(0, [Validators.required ])
    });

    this.agreePrivacy = new UntypedFormControl(false, [Validators.required]);

    if (this.loggedUser.companyId) {
      this.company$.next();
    } else {
      this.patchValues();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  patchValues() {
    this.personalInformationFormGroup.patchValue(this.loggedUser);
    this.sectorFormGroup.patchValue(this.loggedUser);
    this.ovenFormGroup.patchValue(this.loggedUser);
    this.companyFormGroup.patchValue(this.companyLoggedUser);
    this.companyFormGroup.controls['position'].setValue(
      this.loggedUser.position
    );
    this.agreePrivacy.setValue(this.loggedUser.agreePrivacy)
  }

  onSaveDetails() {
    const { id, name, vatNumber, phoneNumber, email, position } =
      this.companyFormGroup.value;
    const company: CompanyDtoNBK = {
      id,
      name,
      vatNumber,
      phoneNumber,
      email
    };

    if (company.vatNumber) {
      this.companyService
        .insertCompany(sessionStorage.getItem('language')!, company)
        .pipe(
          take(1),
          tap((company: CompanyDtoNBK) => {
            const { id, createdTimestamp, roles, username, enabled } =
              this.loggedUser;
            const userToUpdate = {
              ...this.personalInformationFormGroup.value,
              ...this.sectorFormGroup.value,
              ...this.ovenFormGroup.value,
              agreePrivacy: this.agreePrivacy.value,
              id,
              createdTimestamp,
              roles,
              username,
              completed: this.checkCompleted(),
              enabled,
              companyId: company.id,
              position
            };

            this.userService.updateUser(userToUpdate).pipe(take(1)).subscribe();
          })
        )
        .subscribe(async (user: any) => {
          this.toastrService.success(
            this.ts.instant('USER_DETAIL.USER_UPDATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );

          this.keycloakService.loadUserProfile().then((data) => {
            this.mkc.refreshUserData(data);
            (this.mkc.user as any).attributes.completed = [true];
          });
        });
    } else {
      const { id, createdTimestamp, roles, username, enabled } =
        this.loggedUser;
      const userToUpdate = {
        ...this.personalInformationFormGroup.value,
        ...this.sectorFormGroup.value,
        ...this.ovenFormGroup.value,
        agreePrivacy: this.agreePrivacy.value,
        id,
        createdTimestamp,
        roles,
        username,
        completed: this.checkCompleted(),
        enabled,
        position
      };
      this.userService
        .updateUser(userToUpdate)
        .pipe(take(1))
        .subscribe(() => {
          this.toastrService.success(
            this.ts.instant('USER_DETAIL.USER_UPDATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );

          this.keycloakService.loadUserProfile().then((data) => {
            this.mkc.refreshUserData(data);
            (this.mkc.user as any).attributes.completed = [true];
          });
        });
    }
  }

  checkCompleted() {
    return (
      this.personalInformationFormGroup.valid &&
      this.sectorFormGroup.valid &&
      this.ovenFormGroup.valid
    );
  }
}
