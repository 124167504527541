import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { DeviceService, DeviceTechnicianAssociationDtoNBK, UserKeycloakDtoNBK } from 'src/app/api/nbk';
import { dateRangeValidator } from 'src/app/core/date-utils';
import { getTimeZone, KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { FromUTCToLocalePipe } from 'src/app/shared/pipes/from-utcto-locale.pipe';

@Component({
  selector: 'app-service-device-assocation',
  templateUrl: './service-device-association.component.html',
  styleUrls: ['./service-device-association.component.scss']
})
export class ServiceDeviceAssociationComponent implements OnInit, OnDestroy {
  @Input() user: UserKeycloakDtoNBK;
  @Input() dataSource = new ODataFiltrableDataSource<DeviceTechnicianAssociationDtoNBK>();

  unsubscribe$ = new Subject<void>();

  columns: TableColumn[] = [];
  isEditable: any = {};

  dateRangeForm: UntypedFormGroup;

  fieldArray: UntypedFormArray = new UntypedFormArray([]);
  filterControl: UntypedFormControl = new UntypedFormControl('', []);

  @ViewChild('actionTpl', { static: true }) 
  actionTpl: ElementRef<HTMLElement>;

  @ViewChild('dateTpl', { static: true })
  dateTpl: ElementRef<HTMLElement>;
  
  lang: string;
  timezone: string;

  constructor(
    private ts: TranslateService,
    private modalService: NgbModal,
    private toUTCDate: FromUTCToLocalePipe,
    private toastrService: ToastrService,
    private deviceService: DeviceService
  ) {}

  ngOnInit(): void {   
    this.lang = localStorage.getItem(KEY_LANGUAGE_STORAGE)!;
    this.timezone = getTimeZone();
    
    this.columns = [
      {
        prop: 'device.serial',
        name: this.ts.instant('DEVICE.INFO.SERIAL')
      },
      {
        prop: 'device.model',
        name: this.ts.instant('DEVICE.INFO.MODEL')
      },
      {
        name: `${this.ts.instant('DEVICE.TELEMETRY.FROM')} - ${this.ts.instant('DEVICE.TELEMETRY.TO')}`,
        cellTemplate: this.dateTpl,
      },
      {
        prop: '',
        cellTemplate: this.actionTpl,
        maxWidth: 100
      }
    ];

    this.dateRangeForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(new Date(), {validators: [Validators.required]}),
      dateTo: new UntypedFormControl(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000))
    }, { validators: this.dateRangeValidator });

    this.dataSource.loadData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dateRangeValidator (formGroup: AbstractControl) {
    return dateRangeValidator(formGroup);
  }

  toggleEdit(association: DeviceTechnicianAssociationDtoNBK, rowIndex: number) {
    this.isEditable[rowIndex] = !this.isEditable[rowIndex];
    this.dateRangeForm.get("dateFrom")?.setValue(this.toUTCDate.transform(association.from!));
    this.dateRangeForm.get("dateTo")?.setValue( association.to 
      ? this.toUTCDate.transform(association.to!)
      : undefined );
  }

  deleteRow( association: DeviceTechnicianAssociationDtoNBK ): void {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.ts.instant(
      'DEVICES.DEVICE_CARD.MODALS.CONFIRM.TITLE'
    );
    modalRef.componentInstance.message = this.ts.instant(
      'DEVICES.DEVICE_CARD.MODALS.CONFIRM.MESSAGE',
      { deviceName: 'CCCCC' }
    );

    modalRef.componentInstance.confirmAction
      .pipe(
        take(1),
        switchMap(() =>
          this.deviceService.deleteDeviceTechnician(association.id!)
        ),
        finalize(() => {
          modalRef.close();
          this.dataSource.loadData();
        })
      )
      .subscribe();
  }

  editRow(association: DeviceTechnicianAssociationDtoNBK, rowIndex: number) {
    if ( this.dateRangeForm.valid ) {
      let start = new Date(this.dateRangeForm.value.dateFrom).toISOString();
      let end = this.dateRangeForm.value.dateTo ? new Date(this.dateRangeForm.value.dateTo).toISOString() : undefined;
      
      this.deviceService.updateTechincianDateRange(association.id!, start, end)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.toastrService.success(
            this.ts.instant('MY_DISPLAY.DISPLAY_UPDATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
          association.from = start;
          association.to = end;
          this.toggleEdit(association, rowIndex);
        })
      ).subscribe();
    }
  }

  applyFilter() {
    this.dataSource.setSearch(this.filterControl.value);
    this.dataSource.loadData();
  }

  cleanFilter() {
    this.filterControl.reset();
    this.dataSource.removeSeach();
    this.dataSource.loadData();
  }
  
}
