import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { MykeycloakService } from 'src/app/core/mykeycloak.service';
import packageInfo from '../../../../package.json';
import { NavigationService } from './navigation.service';
import { Store } from '@ngrx/store';
import { byCodeSidebar, notByCodeSidebar } from 'src/app/store/sidebar/sidebar.actions';
import { selectIsSidebarByCode } from 'src/app/store/sidebar/sidebar.selectors';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  APP_VERSION: string;

  @ViewChild('aside', { static: false }) aside: ElementRef;

  unsubscribe$: Subject<void> = new Subject();
  byCodeSidebar: boolean;

  constructor(
    public mkc: MykeycloakService,
    public authService: AuthService,
    public ns: NavigationService,
    private renderer: Renderer2,
    private router: Router,
    private store: Store
  ) {
    this.APP_VERSION = packageInfo.version;

    this.ns.toggleNavbar$
    .pipe(
      takeUntil(this.unsubscribe$),
        tap((res) => {
          res ? this.openResponsiveMenu() : this.closeResponsiveMenu();
        })
      )
    .subscribe();
  }

  ngOnInit(): void {
    this.router.events
    .pipe(
      filter((evt) => evt instanceof NavigationStart || evt instanceof Scroll)
    ).subscribe((evt) => {
      if ( this.isRouteWithoutSidebar(evt) ) {
        this.store.dispatch(byCodeSidebar());
        this.ns.toggleNavbar$.next(false);  
        this.ns.toggleHambuger$.next(true);
      } else {
        this.resetNavigation();
      }
    });

    this.store.select(selectIsSidebarByCode).subscribe(byCodeSidebar => {
      this.byCodeSidebar = byCodeSidebar;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout(): void {
    this.mkc.logout();
  }

  openResponsiveMenu() {
    if ( this.byCodeSidebar ) {
      this.renderer.removeClass(this.aside.nativeElement, 'close');
    }
    this.renderer.addClass(this.aside.nativeElement, 'open');
    this.ns.lockLayout$.next(true);
  }

  closeResponsiveMenu() {
    this.renderer.removeClass(this.aside.nativeElement, 'open');
    if ( this.byCodeSidebar ) {
      this.renderer.addClass(this.aside.nativeElement, 'close');
    }
    this.ns.lockLayout$.next(false);
  }

  resetNavigation() {
    this.store.dispatch(notByCodeSidebar());
    this.renderer.removeClass(this.aside.nativeElement, 'close');
    this.renderer.removeClass(this.aside.nativeElement, 'open');
    this.ns.toggleHambuger$.next(false);
    this.ns.lockLayout$.next(false);
  }

  isRouteWithoutSidebar( evt: any ) {
    return evt instanceof NavigationStart && this.matchUrl(evt.url)
      || evt instanceof Scroll && evt.routerEvent instanceof NavigationEnd && this.matchUrl(evt.routerEvent.urlAfterRedirects);
  }

  private matchUrl (url: string) {
    return url.includes('/telemetry') || url.includes('/statistics');
  }
  
}
