<h1>{{ 'NAVIGATION.ROLES' | translate }}</h1>
<h2>
  {{ 'NAVIGATION.ROLES' | translate }}
</h2>

<div class="row filter-box pb-xl-4">
  <div class="col-12 col-xl-10">
    <div class="row">
      <div class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100">
          {{ 'ROLES.FILTER' | translate }}
          <input
            class="custom-input"
            [formControl]="filterControl"
            placeholder="{{ 'ROLES.FILTER_PLACEHOLDER' | translate }}"
          />
        </label>
      </div>
    </div>
  </div>

  <div class="col-12 col-xl-2">
    <div class="filter-button-box">
      <button class="button-primary mb-2" (click)="applyFilter()">
        <i class="fa fa-fw fa-search"></i>{{ 'GLOBAL.FIND' | translate }}
      </button>
      <button class="button-secondary mb-2" (click)="cleanFilter()">
        <i class="fa fa-fw fa-undo"></i>{{ 'GLOBAL.CLEAN' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="table-action-box">
  <button 
    [disabled]="selectionHandler.selectionSize() === 0" 
    class="button-primary" (click)="assignDevicesToUser()">
    <i class="fa fa-plug-circle-plus"></i> {{ 'MY_DISPLAY.ASSIGN_DEVICE' | translate }}
  </button>
  <button class="button-primary" (click)="createUser()">
    <i class="fa fa-user-plus"></i> {{ 'USER.NEW_USER' | translate }}
  </button>
</div>

<div class="row">
  <div class="col-12">
    <app-datatable-paginated
      [dataSource]="dataSource"
      [columns]="columns"
      [className]="'material expandable'"
      [rowDetailHandler]="rowDetailHandler"
      [selectionHandler]="selectionHandler"
      ></app-datatable-paginated>
    </div>
  </div>

<ng-template #rowDetailTemplate let-row="row">
  <app-service-device-assocation 
    [dataSource]="rowDetailDataSource"
    [user]="row"
  ></app-service-device-assocation>
</ng-template>

<ng-template #actionTpl let-row="row" let-rowIndex="rowIndex">
  <!-- <i
    *ngIf="!this.isEditable[rowIndex]"
    (click)="toggleEdit(row, rowIndex)"
    class="table-icons fa fa-fw fa-pen"
  ></i>

  <i
    class="table-icons fa fa-fw fa-save"
    *ngIf="this.isEditable[rowIndex]"
    title="Save"
    (click)="editUser(row, rowIndex)"
  ></i>
  <i
    class="table-icons fa fa-fw fa-times"
    *ngIf="this.isEditable[rowIndex]"
    title="Delete"
    (click)="this.isEditable[rowIndex] = !this.isEditable[rowIndex]"
  ></i> -->
</ng-template>


